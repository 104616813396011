import { H1Bold, H2 } from '../components/shared/typography';
import { graphql, navigate } from 'gatsby';

import React from 'react';
import Seo from '../components/Seo';
import { StyledContent } from '../components/layout/layout';
import { regexSlug } from '../components/shared/utils';
import styled from '@emotion/styled';

const StyledCol = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  height: 200px;
  width: 30%;
  border-radius: 5px;
  transition: 0.2s ease;
  cursor: pointer;
  background-size: cover;
  opacity: 0.8;
  margin-bottom: 2rem;

  &:hover {
    transform: scale(1.02);
    opacity: 1;
  }

  @media (max-width: 760px) {
    width: 48%;
  }


  @media (max-width: 450px) {
    width: 100%;
  }
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem;
`

const TitleText = styled(H2)`
  width: 100%;
  text-align: center;
  /* position: absolute; */
  z-index: 10;
  background: rgb(255, 255, 255, 0.9);
  align-self: center;
  padding: 1rem;
  text-transform: uppercase;

  @media (max-width: 760px) {
    font-size: 25px;
    line-height: 25px;
  }

  @media (max-width: 500px) {
    font-size: 16px;
    padding: 0.2rem;
    line-height: 20px;
  }
`

const District: React.FC = ({
  data: { collections }}: any) => {

  const allD = collections.nodes.map((c: any) => c.title)

  return (
    <StyledContent style={{padding: '0 0.5rem', paddingTop: '2rem'}}> 
      <Seo
        title={'Alle producenter - Eksklusive vinproducenter'}
        description={'Hos ManstedWine.dk tilbyder vi vine fra en række eksklusive vinproducenter. Vores vinproducenter er nøje udvalgt, ud fra deres erfaring, know-how, passion og selfvølgelig vinkvalitet'}
        image={collections.nodes[0].image?.originalSrc}
        keywords={allD}
      />
      <H1Bold style={{width: '100%', textAlign: 'center'}}>Alle distrikter</H1Bold>
      <Container>
      {collections.nodes && collections.nodes.map((collection: any, key: number) => (
        <StyledCol 
          key={key} 
          style={{backgroundImage: collection?.image && collection?.image?.originalSrc ? `url(${collection?.image?.originalSrc})` : 'url(/placeholder.png)'}}
          onClick={() => navigate(`/vine/${regexSlug(collection.title.toLowerCase())}/`)}
        >
          <TitleText>{collection.title}</TitleText>
        </StyledCol>
      ))}
      </Container>
    </StyledContent>
  )
};
export default District;

export const query = graphql`
  query {
    collections: allShopifyCollection(filter: { metafields: { elemMatch: { value: { eq: "area" } } } }) {
      nodes {
        title
        descriptionHtml
        image {
          originalSrc
        }
      }
    }
  }
`;
